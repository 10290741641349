import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'

const Freude = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0 d-none d-sm-block' >
                    <div className='position-absolute text-white h1FontFamily1' style={{ top: "24%", left: "6%" }} >
                        <h2 style={{ fontSize: "5.059375vw",fontWeight:"600" }}>Freude</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '700px' }}  >
                        <source src={require("../Image/Header5Video.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="container-fluid p-0 d-sm-none d-block">
                    <div className='position-absolute text-white h1FontFamily1 ' style={{ top: "46%", left: "10%" }} >
                        <h2 style={{ fontSize: "36px", fontWeight: "600" }}>Freude</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '100%' }}  >
                        <source src={require("../Image/Header5ResponsiveVideo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>

            {/* <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../Image/FreudeBackgroundImg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
                    <div className="container circular-world-home-bg">
                        <div className="row  d-flex justify-content-center align-itmes-center  text-white " >
                            <div className=" mt-5">
                                <div className='fontFamilyHeader1 pt-lg-5 mt-lg-5'>
                                    <h4 className='mt-lg-5 reamaning-heading-common-size text-white'>Freude</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section> */}

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row ms-lg-5">
                            <div className='fontFamilyHeader1 p-lg-4 mt-2 mb-2 common-font-size' >
                                <p>Joy can be so many things. A statement, driving pleasure, enjoying life conscientiously. And joy is both a driver and a promise. Whatever else it may be, joy is a powerful and intense feeling that lets us look positively to the future. With stories that stir your senses and tales that grab your attention, we show you how joy determines our lives and therefore also our mobility.</p>
                            </div>
                            <div>
                                <p className='ms-lg-3' style={{ color: "#8E8EA7", fontSize: "18px" }}>18 April 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Lady2.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Creative joy <br></br>Luxury redefined</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/Freudevideo1.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> BMW explained </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Learn everything about BMW here </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/LadyCar.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> Car photography </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Anna Heupel: always moving </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR4.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Individual mobility <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>When you can’t stop sheer driving pleasure </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR3.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>OBSESSED X BMW <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Ruba Abu-Nimah and Tyshawn jones</p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Boy&Lady.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> BMW Lifestyle  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Joshua Vides to dress: understated cool </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/14CAR.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>Halloween comic</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Out of the shadows and into the light </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/4672CAR.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>friendly enjoyment </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Time for sustainable luxury at Le Jardin de Berne</p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR5.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>JOY is three letters  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Discover the beauty of simple things that bring us JOY  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/7SenseBook.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>  FREUDE.FOREVER<br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>A new magazine presented by BMW </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/START.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>BMW BERLIN-MARATHON  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The joy of pushing the limits </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/rrrr.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Podcasts  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> BMW Podcasts </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/CARBOY.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>  New luxury  <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> Tantris starred restaurant: the future of indulgence </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/SKY.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> 50 years: modernity  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Celebrating half a century of BMW innovation </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/2OLDMAN.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> Car design </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> Masters of form  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/BMWlegend.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>  BMW M legend   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Jochen Neerpasch: the motorsport mentor  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/314CAR.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Dream drive  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> On the road with the new BMW 8 Series </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Lady&Car.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Electric fleet   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Cannes, Côte d’Azur and BMW: a camera-ready cast </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/OBS_BMW.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> OBSESSED X BMW  </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Ishod Wair </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/3CARS.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> 50 years of BMW M  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> 7 milestones in the history of BMW M  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Cartoon.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>  Ride with pride   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Celebrating all colors of Freude  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/RedCar.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>   “We still believe in cars”      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   BMW combines sustainability with a passion for cars    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF14.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Performance      <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>   Performance     </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Freude1.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> THE 8 X JEFF KOONS     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   When an artist creates his dream car himself...   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center bg-white pt-5 pb-5 '>
                            <NavLink to='/models'><button className='p-2  SHOWMOREBUTTON' style={{ border: "2px solid black", }}><b className='p-lg-5  h1FontFamily1' style={{ fontSize: "17px" }}>Show more</b></button></NavLink>
                        </div>

                    </div>
                </div>
            </section>

            

        </>
    )
}

export default Freude