import React from 'react'
import { NavLink } from 'react-router-dom'
import "../components/Comman.css";

const Header = () => {

    return (
        <>
            <section >
                <nav className="navbar  navbar-expand-lg  text-white p-lg-0  header-content d-none d-sm-block "  >
                    <div className="container">
                        <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars text-light p-2" style={{ border: "1px solid white" }} ></i>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarNavDropdown">
                            <div className="container p-3">
                                <div className="row " >
                                    <ul className="navbar-nav d-flex justify-content-center fontFamilyHeader1"  >
                                        <li className='nav-item '>
                                            <NavLink className="text-white text-decoration-none  " to="/" > <p className='HEADERLINK ' >Home</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none  " to="/circularworld"> <p className='HEADERLINK ' >Circular World</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none  " to="/digitaljourney"> <p className='HEADERLINK ' >Digital Journey</p> </NavLink>
                                        </li>
                                        <li className='nav-item ' >
                                            <NavLink className="text-white text-decoration-none  " to="/electricfuture"> <p className='HEADERLINK ' >Electric Future</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none   " to="/freude"> <p className='HEADERLINK ' >Freude</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none   " to="/models"> <p className='HEADERLINK ' >Models</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none   " > <p className='HEADERLINK1 ' style={{ padding: "5px", fontSize: "19px",cursor:"initial" }} >|</p> </NavLink>
                                        </li>
                                        <li className='nav-item' >
                                            <NavLink className="  text-decoration-none"  >  <p className='HEADERLINK1 text-white' style={{ padding: "7px", fontSize: "15px", paddingBottom: "9px",cursor:"initial" }}><i className="fa-solid fa-globe  text-white " ></i><b className='ms-2 ' style={{ fontSize: "13px" }} >BMW</b> in your country</p> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='row  fontFamilyHeader d-flex justify-content-end align-items-end' style={{ lineHeight: '14px' }}>
                                    <p className='text-white fs-5 shree-driving-pleasure' ><b className='fs-6'>Shree Driving</b> <strong className='fs-6'>Pleasure</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className=' '>
                                    <NavLink to='/'><img className=' ' style={{ height: "5rem" }} src={require("../Image/BMW_LOGO_Hedaer.png")} alt="" /></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <nav className="navbar  navbar-expand-lg  text-white p-lg-0 text-white header-content  d-sm-none d-block" >
                    <div className="container">
                        
                        <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars text-light p-2" style={{ border: "1px solid white" }} ></i>
                        </button>
                        <div className='mt-2 '>
                            <NavLink to='/'><img className=' ' style={{ height: "5rem" }} src={require("../Image/logo-light-resp2.png")} alt="" /></NavLink>
                        </div>
                        <div className="collapse navbar-collapse " id="navbarNavDropdown">
                            <div className="container p-3">
                                <div className="row " >
                                    <ul className="navbar-nav d-flex justify-content-center fontFamilyHeader1"  >
                                        <li className='nav-item '>
                                            <NavLink className="text-white text-decoration-none  " to="/" > <p className='HEADERLINK ' >Home</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none  " to="/circularworld"> <p className='HEADERLINK ' >Circular World</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none  " to="/digitaljourney"> <p className='HEADERLINK ' >Digital Journey</p> </NavLink>
                                        </li>
                                        <li className='nav-item ' >
                                            <NavLink className="text-white text-decoration-none  " to="/electricfuture"> <p className='HEADERLINK ' >Electric Future</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none   " to="/freude"> <p className='HEADERLINK ' >Freude</p> </NavLink>
                                        </li>
                                        <li className='nav-item  ' >
                                            <NavLink className="text-white text-decoration-none   " to="/models"> <p className='HEADERLINK ' >Models</p> </NavLink>
                                        </li>
                                        <li className='nav-item' >
                                            <NavLink className="  text-decoration-none"  >  <p className='HEADERLINK1 text-white' style={{ padding: "4px", fontSize: "19px", paddingBottom: "9px" }}><i className="fa-solid fa-globe  text-white " ></i><b className='ms-2 ' style={{ fontSize: "19px" }} >BMW</b> in your country</p> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='row fontFamilyHeader d-flex justify-content-end align-items-end' style={{ lineHeight: '14px' }}>
                                    <p className='text-white fs-5 shree-driving-pleasure' ><b className='fs-6'>Shree Driving</b> <strong className='fs-6'>Pleasure</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Header