import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'

const DigitalJourney = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-none d-sm-block">
                    <div className='position-absolute text-dark h1FontFamily1' style={{ top: "24%", left: "6%" }} >
                        <h2 style={{ fontSize: "5.059375vw",fontWeight:"600" }}>Digital Journey</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '700px' }}  >
                        <source src={require("../Image/Header3Video.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="container-fluid p-0 d-sm-none d-block">
                    <div className='position-absolute text-dark h1FontFamily1' style={{ top: "43%", left: "8%" }} >
                        <h2 style={{ fontSize: "36px" , fontWeight: "600"}}>Digital Journey</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '100%' }}  >
                        <source src={require("../Image/Header3ResponsiveVideo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>

            {/* <section>
                <div className='container-fluid ' >
                    <div className="container circular-world-home-bg" >
                        <div className="row  d-flex justify-content-center align-itmes-center  text-white " >
                            <div className=" mt-5">
                                <div className='fontFamilyHeader1 pt-lg-5 mt-lg-5'>
                                    <h4 className=' mt-lg-5 reamaning-heading-common-size pt-lg-5'>Digital Journey</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </section> */}

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row ms-lg-5">
                            <div className='fontFamilyHeader1 p-lg-4 mt-2 mb-2 common-font-size'>
                                <p>Never before has the spectrum of mobility been so fascinating and automotive progress promised so much. We take you on a digital journey to the exciting topics of our time and through the BMW universe. Sheer driving pleasure is transposed to the digital future. At BMW, digitalization is a path that serves people’s well-being, enables networking, and makes it even easier to experience joy.</p>
                            </div>
                            <div>
                                <p className='ms-lg-3' style={{ color: "#8E8EA7", fontSize: "18px" }}>18 April 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/DJImg1.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Audio column  <br></br>The future of startups in the automotive industry </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/DJImg2.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> Bold Magical Woman </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> The true power of womanhood </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/DJImg3.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> \\ welcome to my hub! // </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> \\ hi, i'm Dee! // </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/DJimg5.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> 50 years of Designworks     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Designworks – innovation through the ages  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR7.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Going green  <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> Travel with the BMW iX: sustainable enjoyment in South Tyrol   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Djimg6.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Audio column   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  The future of industrial technology trends   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/Djimg7.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Rafael Lozano-Hemmer  </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Visualizing the invisible  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/Djimg8.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Audio column  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>The future of audio and acoustics   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR8.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Future of Tech  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Experience the technology of the future today: Entertainment  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Djimg9.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  BMW x Swarovski     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The new light on the road  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Djimg10.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Audio column   <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  The future of visual arts    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Djimg11.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Hidden gems    <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> The BMW concept cars you need to know  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR6.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Audio column   <br></br>Utopia in progress  </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/DigitalJourney1.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> THE 8 X JEFF KOONS  </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Creating uniqueness  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/DigitalJourney2.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> My BMW App  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> Connect your life  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR9.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  BMW Service     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Bizarre, funny, emotional: the exploits of BMW drivers  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/DigitalJourney3.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> How can we RE:IMAGINE TODAY?  <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  Welcome to JOYTOPIA    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR10.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>BMW explained     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Unravelling the mystery of the BMW naming system   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/DigitalJourney4.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Design   </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Style guide: How BMW car design comes about   </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR11.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b>  Interior innovation  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Eyes on the road, hands on the wheel    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/2CAR&snake.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Edition Black Vermilion   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> BMW X5 and BMW X6 in black and red: inspired by nature   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR19.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  Art-ificial intelligence     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> THE 8: artificial intelligence turns car into artwork   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/DigitalJourney5.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Jeff Koons   <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  Trust in yourself    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR12.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Technology explained     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  The meaning of torque – what you need to know   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center bg-white pt-5 pb-5 '>
                            <NavLink to='/models'><button className='p-2  SHOWMOREBUTTON' style={{ border: "2px solid black", }}><b className='p-lg-5  h1FontFamily1' style={{ fontSize: "17px" }}>Show more</b></button></NavLink>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default DigitalJourney