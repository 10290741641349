import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'

const Home = () => {

  useEffect(() => {
    var items = document.querySelectorAll('.carousel .carousel-item')
    console.log(items);
    items.forEach((el) => {
      const minPerSlide = 5;

      var next = el.nextElementSibling
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0]
        }
        var cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
      }
    })
  }, [])

  return (
    <>

      <section>
        <div className="container-fluid p-0 d-none d-sm-block" style={{ backgroundColor: "transparent" }}>

          {/* <div className=' child mt-lg-5'>
            <div className=' '>
              <div className="container">
                <div className="row text-white d-flex justify-content-center align-itmes-center  text-white " >
                  <div className=" ContentHover ">
                    <div className=''>
                      <h4 className=' Audio-responisve-size '>Audio Column</h4>
                    </div>

                    <div className=''>
                      <h1 className=' home-main-tital-size'> The future of startups in the <br></br> automotive industry</h1>
                    </div>

                    <div className=''>
                      <NavLink to=''><button className='p-2  home-bg-button' ><b className='p-lg-5 h1FontFamily1 '>Read More</b></button></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='HoverEffectHomeBG'>
            {/* <Header /> */}
            <div className='position-relative '>

              <img className=' w-100 Img-Height ' style={{ height: "570px" }} src={require("../Image/HomeUpdateBg.png")} alt="" />

              <div className='position-absolute ' style={{ bottom: "8%", left: "7%" }}>
                <div className=" ContentHover text-white">
                  <div className=''>
                    <h4 className=' Audio-responisve-size '>Audio Column</h4>
                  </div>

                  <div className=''>
                    <h1 className=' home-main-tital-size'> The future of startups in the <br></br> automotive industry</h1>
                  </div>

                  <div className=''>
                    <NavLink to='/freudestays'><button className='p-2  home-bg-button' ><b className='p-lg-5 h1FontFamily1 '>Read More</b></button></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-sm-none d-block" style={{ backgroundColor: "transparent" }}>
          <div className='HoverEffectHomeBG'>
            <div className='position-relative '>
              <img className=' w-100 Img-Height ' style={{ height: "500px" }} src={require("../Image/HomeResponsive.png")} alt="" />
              <div className='position-absolute ' style={{ bottom: "4%", left: "6%" }}>
                <div className=" ContentHover text-white">
                  <div className=''>
                    <h4 className=' Audio-responisve-size '>Audio Column</h4>
                  </div>

                  <div className=''>
                    <h1 className=' home-main-tital-size'> The future of startups in the <br></br> automotive industry</h1>
                  </div>

                  <div className=''>
                    <NavLink to='/freudestays'><button className='p-2  home-bg-button' ><b className='p-5 h1FontFamily1 '>Read More</b></button></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="row" style={{ borderTop: "1px solid white" }}>
            <div className="col-lg-6 p-0 HoverEffect">
              <div className='position-relative '>
                <img className=' w-100 Img-Height' style={{ borderRight: "2px solid white", borderTop: "2px solid white" }} src={require("../Image/Bike1.png")} alt="" />
                <div className='position-absolute ' style={{ bottom: "8%", left: "7%" }}>
                  <h2 className='fontFamilyHeader1 text-color bmw-films-size' >BMW Films: THE CALM</h2>
                  <div className=''>
                    <NavLink to='/freudestays'><button className='p-3 mt-4 BTNREADMORE1'  ><b className='p-lg-5 h1FontFamily1 '>Read More</b></button></NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 p-0">
              <div className='position-relative HoverEffect'>
                <img className='w-100 Img-Height' style={{ borderLeft: "2px solid white", borderTop: "2px solid white" }} src={require("../Image/Lady.png")} alt="" />
                <div className='position-absolute text-white' style={{ bottom: "8%", left: "7%" }}>
                  <h4 className='h1FontFamily1 fw-bold the-icon'>THE ICON</h4>
                  <h2 className='fontFamilyHeader1 mt-3 bmw-films-size' >BMW as a driving force for sustainable mobility</h2>
                  <div className=''>
                    <NavLink to='/freudestays'><button className='p-3 mt-4 BTNREADMORE '  ><b className='p-lg-5 h1FontFamily1 '>Read More</b></button></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row d-flex justify-content-around align-items-center">
              <div className="col-lg-6 mt-5 pt-lg-3 mb-lg-5">
                <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill" }}  >
                  <source src={require("../Image/BMWvideo.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="col-lg-4 mt-lg-3 mt-2 mb-5 text-lg-center">
                <h2 className='fontFamilyHeader1 Learn-everything-size' >Learn everything  about BMW here</h2>
                <div className=''>
                  <NavLink to=''><button className='p-2 mt-4 BMW_EXPLAINEDBUTTON' style={{ border: "2px solid black", height: "50px" }} ><b className='p-lg-5 h1FontFamily1 '>BMW EXPLAINED</b></button></NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid bg-dark" >
          <div className="row pt-lg-3 pb-lg-5  text-white d-flex justify-content-end ">
            <div className="col-lg-3 ps-lg-5  pb-lg-3">
              <div className='ps-lg-4'>
                <h3 className='fs-1 h1FontFamily mt-3'>THE BMW PODCAST: CHANGING LANES</h3>
                <p className='fontFamilyHeader1 mt-3' style={{ fontSize: "17px", lineHeight: "22px" }} >"Changing Lanes” is the official podcast of BMW. Featuring new episodes each week, in which our hosts take you on exciting journeys and talk about innovative technologies, lifestyle, design, cars and more. Find and subscribe to Changing Lanes on all major podcasting platforms.</p>
                <div className=''>
                  <NavLink to=''><button className='p-2 mt-3  btn-dark btn-outline-light ' style={{ border: "2px solid white", height: "50px" }} ><b className='p-lg-5 h1FontFamily1 '>Listen now</b></button></NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-9 pt-lg-3 pb-lg-3 p-0">
              <div className="container text-center my-3">
                <div className="row  mx-lg-auto my-lg-auto justify-content-center">
                  <div id="recipeCarousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner " role="listbox">
                      <div className="carousel-item  active" >
                        <div className="col-md-3 p-2 ">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder1.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item  ">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder2.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item ">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder3.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item ">
                        <div className="col-md-3 p-2 ">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder4.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder5.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder6.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder7.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder8.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder9.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="col-md-3 p-2">
                          <div className="card border-0">
                            <div className="card-img position-relative HoverEffect">
                              <img className='w-100' src={require("../Image/Silder10.webp")} alt="Reviews1" />
                            </div>
                            <div className='position-absolute bottom-0  p-2 text-light text-start' >
                              <h6 className=''>Audio Column</h6>
                              <h6 className=''>The future of startups in the automotive industry</h6>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <a className="carousel-control-prev bg-transparent w-aut " href="#recipeCarousel" role="button" data-bs-slide="prev">

                      <i class="fa-solid fa-angles-left bg-dark p-2 " aria-hidden="true" ></i>
                    </a>
                    <a className="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                      <i class="fa-solid fa-angles-right bg-dark p-2" aria-hidden="true" ></i>
                    </a>
                  </div>
                </div>

              </div>


            </div>



          </div>
        </div>



      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row text-center pt-5 pb-5">
              <div>
                <h4 className=' h1FontFamily1 BMW-your-country' >BMW in your country</h4>
                <h2 className=' SolutionDropDownFontFamily3 ALL-BMW-MODELS'>ALL BMW MODELS</h2>
                <NavLink className='text-dark text-decoration-none ' to=''><h3 className=' SolutionDropDown   Find-your-BMW' ><i class="fa-solid fa-angles-right " aria-hidden="true" >&nbsp;&nbsp;</i><b>Find your BMW</b></h3></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-8 p-0">
              <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Lady2.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Creative joy <br></br>Luxury redefined</h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0 gy-0 " >

              <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                  <source src={require("../Image/videoCAR2.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>Circle of life</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The journey of a BMW vehicle at the recycling center</p></h5>
                </div>
              </div>

              <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/CAR5304.png")} alt="" />
                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>Eco-friendly</b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Time for sustainable luxury at Kashiwaya</p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/CarLady.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Attitude <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Dedication, determination and drive</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffectVIDEO h-100 position-relative">
                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                  <source src={require("../Image/videoCAR3.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>OBSESSED X BMW <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Ruba Abu-Nimah and Tyshawn jones</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
              <div className="HoverEffectVIDEO h-100 position-relative">
                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                  <source src={require("../Image/videoCAR4.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Individual mobility <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>When you can't stop shree driving pleasure</p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-4 p-0 gy-0 " >
              <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                  <source src={require("../Image/videoCAR5.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>JOY is three letters</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Discover the beauty of simple things that bring us JOY</p></h5>
                </div>
              </div>

              <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/Boy&Lady.png")} alt="" />
                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>BMW Lifestyle</b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Joshua Vides to dress: understated cool</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-8 p-0">
              <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/LadyCar.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Car photography <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Anna Heupel: always moving</p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/14CAR.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Halloween comic  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Out of the shadows and into the light</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/4672CAR.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Eco-friendly enjoyment <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Time for sustainable luxury at Le Jardin de Berne</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/7SenseBook.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>FREUDE.FOREVER <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>A new magazine presented by BMW</p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-8 p-0">
              <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/START.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>BMW BERLIN-MARATHON <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>The joy of pushing the limits</p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0 gy-0 " >

              <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/rrrr.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> BMW Podcasts </b><br></br></h5>
                </div>
              </div>

              <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/CARBOY.png")} alt="" />
                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>New luxury </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> Tantris starred restaurant: the future of indulgence </p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/SKY.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> 50 years: modernity   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Celebrating half a century of BMW innovation </p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/2OLDMAN.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Car design <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Masters of form </p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/314CAR.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Dream drive  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> On the road with the new BMW 8 Series </p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-4 p-0 gy-0 " >
              <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/Cartoon.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>Ride with pride</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Celebrating all colors of Freude </p></h5>
                </div>
              </div>

              <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/Lady&Car.png")} alt="" />
                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                  <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>Electric fleet</b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Cannes, Côte d’Azur and BMW: a camera-ready cast </p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-8 p-0">
              <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/BMWlegend.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>BMW M legend  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Jochen Neerpasch: the motorsport mentor </p></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffectVIDEO h-100 position-relative">
                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                  <source src={require("../Image/OBS_BMW.mp4")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> OBSESSED X BMW   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Ishod Wair </p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/3CARS.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>50 years of BMW M  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  7 milestones in the history of BMW M  </p></h5>
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
              <div className="HoverEffect h-100 position-relative">
                <img className='w-100' style={{ height: "100%" }} src={require("../Image/RedCar.png")} alt="" />

                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                  <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>  “We still believe in cars”   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  BMW combines sustainability with a passion for cars  </p></h5>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-center align-items-center bg-white pt-5 pb-5'>
              <NavLink to='/models'><button className='p-2  SHOWMOREBUTTON' style={{ border: "2px solid black", }}><b className='p-lg-5  h1FontFamily1' style={{ fontSize: "17px" }}>Show more</b></button></NavLink>
            </div>
          </div>
        </div>
      </section>

      

    </>

  )
}

export default Home