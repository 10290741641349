import React from 'react'
import { NavLink } from "react-router-dom";

const Header2 = () => {

    return (

        <>

            <nav className="navbar navbar-expand-lg p-0 text-dark   ">
                <div className="container ">
                    <div className="row pt-2 d-flex w-100 "  >

                        <div className="col-lg-12 pb-3 ">
                            <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars text-dark"></i>
                            </button>
                            <div className="collapse navbar-collapse mt-lg-0  mt-3 " id="navbarNavDropdown">
                                {/* <div className=' pt-2' >
                                    <NavLink to='/'><img className=' ' style={{ height: "60px" }} src={require("../Image/SecondHeaderLogoBMW.png")} alt="" /></NavLink>
                                </div> */}
                                <ul className="navbar-nav ms-lg-3 ps-lg-3  d-flex justify-content-lg-around align-items-lg-center w-100"  >

                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2 '>
                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size" to="/models" > <b className=''>Models</b> </NavLink>
                                    </li>
                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Electric</b> </NavLink>
                                    </li>
                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Configurator</b> </NavLink>
                                    </li>
                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Visit Online Shop</b> </NavLink>
                                    </li>
                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size" to='/'> <b className=''>More BMW</b> </NavLink>
                                    </li>
                                </ul>

                                {/* <ul className='navbar-nav ms-lg-3 ps-lg-3  d-flex justify-content-end w-100'>
                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                            <i class="fa-solid fa-cart-plus"></i>
                                        </button>
                                    </li>

                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                            <i class="fa-solid fa-location-dot"></i>
                                        </button>
                                    </li>

                                    <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                        <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                            <i className="fa-solid fa-magnifying-glass "></i>
                                        </button>
                                    </li>
                                </ul> */}

                            </div>
                        </div>

                    </div>
                </div>
            </nav>

            {/* <nav className="navbar navbar-expand-lg p-0 text-dark text-dark d-sm-none d-block  ">
                <div className="container p-2" style={{borderBottom:"1px solid black"}}>
                    <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars text-dark"></i>
                    </button>
                    <div className=' pt-2' >
                        <NavLink to='/'><img className=' ' style={{ height: "60px" }} src={require("../Image/SecondHeaderLogoBMW.png")} alt="" /></NavLink>
                    </div>

                    <div className="collapse navbar-collapse mt-lg-0  mt-3 " id="navbarNavDropdown">
                        <ul className="navbar-nav ms-lg-3 ps-lg-3  d-flex justify-content-lg-around align-items-lg-center w-100"  >

                            <li className='nav-item mt-lg-2 p-2 mt-lg-2 '>
                                <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }} to="/models" > <b className=''>Models</b> </NavLink>
                            </li>
                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className=''>Electric</b> </NavLink>
                            </li>
                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className=''>Configurator</b> </NavLink>
                            </li>
                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className=''>Visit Online Shop</b> </NavLink>
                            </li>
                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }} to='/' > <b className=''>More BMW</b> </NavLink>
                            </li>
                        </ul>

                        <ul className='navbar-nav ms-lg-3 ps-lg-3  d-flex justify-content-end w-100'>
                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                    <i class="fa-solid fa-cart-plus"></i>
                                </button>
                            </li>

                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                    <i class="fa-solid fa-location-dot"></i>
                                </button>
                            </li>

                            <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                <button className=" btn bg-body" style={{ borderRadius: "10px 10px 0px 10px" }}>
                                    <i className="fa-solid fa-magnifying-glass "></i>
                                </button>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav> */}

        </>



    )
}

export default Header2